/* eslint-disable react/destructuring-assignment */
/** Information
 * Reusable Icon element
 * Feather Icons: https://feathericons.com/
 * Octicons: https://primer.style/octicons/
 */

// Import Module(s)
import React from 'react'
import { Box, BoxProps, ResponsiveValue, Icon as ChakraIcon } from '@chakra-ui/react'
import FeatherIcon from 'feather-icons-react'
import { Octicon, Octicons } from 'octicons-react'
import { get } from 'lodash'
import * as theme from '../../../theme'
import * as CSS from 'csstype'

export const featherIcons = [
  'activity',
  'airplay',
  'alert-circle',
  'alert-octagon',
  'alert-triangle',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'anchor',
  'aperture',
  'archive',
  'arrow-down-circle',
  'arrow-down-left',
  'arrow-down-right',
  'arrow-down',
  'arrow-left-circle',
  'arrow-left',
  'arrow-right-circle',
  'arrow-right',
  'arrow-up-circle',
  'arrow-up-left',
  'arrow-up-right',
  'arrow-up',
  'at-sign',
  'award',
  'bar-chart-2',
  'bar-chart',
  'battery-charging',
  'battery',
  'bell-off',
  'bell',
  'bluetooth',
  'bold',
  'book-open',
  'book',
  'bookmark',
  'box',
  'briefcase',
  'calendar',
  'camera-off',
  'camera',
  'cast',
  'check-circle',
  'check-square',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevrons-left',
  'chevrons-right',
  'chevrons-up',
  'chrome',
  'circle',
  'clipboard',
  'clock',
  'cloud-drizzle',
  'cloud-lightning',
  'cloud-off',
  'cloud-rain',
  'cloud-snow',
  'cloud',
  'code',
  'codepen',
  // 'codesandbox',
  // 'coffee',
  // 'columns',
  'command',
  'compass',
  'copy',
  'corner-down-left',
  'corner-down-right',
  'corner-left-down',
  'corner-left-up',
  'corner-right-down',
  'corner-right-up',
  'corner-up-left',
  'corner-up-right',
  'cpu',
  'credit-card',
  'crop',
  'crosshair',
  'database',
  'delete',
  'disc',
  // 'divide-circle',
  // 'divide-square',
  // 'divide',
  'dollar-sign',
  'download-cloud',
  'download',
  // 'dribble',
  'droplet',
  'edit-2',
  'edit-3',
  'edit',
  'external-link',
  'eye-off',
  'eye',
  'facebook',
  'fast-forward',
  'feather',
  // 'figma',
  'file-minus',
  'file-plus',
  'file-text',
  'file',
  'film',
  'filter',
  'flag',
  'folder-minus',
  'folder-plus',
  'folder',
  // 'framer',
  'frown',
  'gift',
  'git-branch',
  'git-commit',
  'git-merge',
  'git-pull-request',
  'github',
  'gitlab',
  'globe',
  'grid',
  'hard-drive',
  'hash',
  'headphones',
  'heart',
  'help-circle',
  // 'hexagon',
  'home',
  'image',
  'inbox',
  'info',
  'instagram',
  'italic',
  // 'key',
  'layers',
  'layout',
  'life-buoy',
  'link-2',
  'link',
  'linkedin',
  'list',
  'loader',
  'lock',
  'log-in',
  'log-out',
  'mail',
  'map-pin',
  'map',
  'maximize-2',
  'maximize',
  'meh',
  'menu',
  'message-circle',
  'message-square',
  'mic-off',
  'mic',
  'minimize-2',
  'minimize',
  'minus-circle',
  'minus-square',
  'minus',
  'monitor',
  'moon',
  'more-horizontal',
  'more-vertical',
  // 'mouse-pointer',
  'move',
  'music',
  'navigation-2',
  'navigation',
  'octagon',
  'package',
  'paperclip',
  'pause-circle',
  'pause',
  // 'pen-tool',
  'percent',
  'phone-call',
  'phone-forwarded',
  'phone-incoming',
  'phone-missed',
  'phone-off',
  'phone-outgoing',
  'phone',
  'pie-chart',
  'play-circle',
  'play',
  'plus-circle',
  'plus-square',
  'plus',
  'pocket',
  'power',
  'printer',
  'radio',
  'refresh-ccw',
  'refresh-cw',
  'repeat',
  'rewind',
  'rotate-ccw',
  'rotate-cw',
  'rss',
  'save',
  'scissors',
  'search',
  'send',
  'server',
  'settings',
  'share-2',
  'share',
  'shield-off',
  'shield',
  'shopping-bag',
  'shopping-cart',
  'shuffle',
  'sidebar',
  'skip-back',
  'skip-forward',
  'slack',
  'slash',
  'sliders',
  'smartphone',
  'smile',
  'speaker',
  'square',
  'star',
  'stop-circle',
  'sun',
  'sunrise',
  'sunset',
  'tablet',
  'tag',
  'target',
  'terminal',
  'thermometer',
  'thumbs-down',
  'thumbs-up',
  'toggle-left',
  'toggle-right',
  // 'tool',
  'trash-2',
  'trash',
  // 'trello',
  'trending-down',
  'trending-up',
  'triangle',
  'truck',
  'tv',
  'twitch',
  // 'twitter',
  'type',
  'umbrella',
  'underline',
  'unlock',
  'upload-cloud',
  'upload',
  'user-check',
  'user-minus',
  'user-plus',
  'user-x',
  'user',
  'users',
  'video-off',
  'video',
  'voicemail',
  'volume-1',
  'volume-2',
  'volume-x',
  'volume',
  'watch',
  'wifi-off',
  'wifi',
  'wind',
  'x-circle',
  // 'x-octagon',
  'x-square',
  'x',
  'youtube',
  'zap-off',
  'zap',
  'zoom-in',
  'zoom-out',
]
// const GoogleIcon = (props) => (
//   <ChakraIcon viewBox='0 0 533.5 544.3' {...props}>
//     <path
//       d='M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z'
//       fill='#4285f4'
//     />
//     <path
//       d='M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z'
//       fill='#34a853'
//     />
//     <path d='M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z' fill='#fbbc04' />
//     <path
//       d='M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z'
//       fill='#ea4335'
//     />
//   </ChakraIcon>
// )
const CustomIcon = (props) => {
  const allProps = { ...props }
  if (!props.resize) {
    allProps.width = props.width || props.size || 20
    allProps.height = props.height || props.size || 20
  }
  delete allProps.minWidth
  const svgs = {
    footer: (
      <svg fill='none' {...allProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M5,3h14 c1.104,0,2,0.896,2,2v14c0,1.104-0.896,2-2,2H5c-1.104,0-2-0.896-2-2V5C3,3.896,3.896,3,5,3z'
        />
        <line
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          x1='3'
          y1='13'
          x2='21'
          y2='13'
        />
        <rect fill='currentColor' x='4' y='13' width='16' height='7' />
      </svg>
    ),
    sidebar: (
      <svg
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...allProps}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
      >
        <path
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M5,3h14 c1.104,0,2,0.896,2,2v14c0,1.104-0.896,2-2,2H5c-1.104,0-2-0.896-2-2V5C3,3.896,3.896,3,5,3z'
        />
        <line
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          x1='13'
          y1='3'
          x2='13'
          y2='21'
        />
        <rect fill='currentColor' x='13' y='4' width='6' height='16' />
      </svg>
    ),
    kanban: (
      <svg
        fill='none'
        {...allProps}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
        <rect x='7' y='7' width='3' height='9' />
        <rect x='14' y='7' width='3' height='5' />
      </svg>
    ),
    stack: (
      <svg fill='none' {...allProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12 2L2 7L12 12L22 7L12 2Z'
          stroke={props.color2 || 'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M2 17L12 22L22 17' stroke={props.color || 'black'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2 12L12 17L22 12' stroke={props.color2 || 'white'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    ),
    rocket: (
      // <Image src='https://missionbase-resources.s3.amazonaws.com/website/rocket+white+192.png'  boxSize="20px" />
      <ChakraIcon
        // width={props.size}
        // height={props.size}
        // fill='none'
        {...props}
        viewBox='0 0 512 512'
        // xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M183.586 364.043C200.455 501.136 62.0274 444.424 1 511C23.8232 425.192 8.44236 304.864 138.932 319.166C87.8275 321.656 79.3929 399.055 79.3929 430.124C116.109 391.658 171.182 422.233 183.586 364.043Z'
          fill='#F75314'
        />
        <path
          d='M502.441 1.03682C467.414 4.39655 437.002 9.61746 409.556 17.4764L494.762 99.5006C502.979 72.3666 508.015 42.8499 510.969 9.45493C511.398 4.60491 507.307 0.570135 502.441 1.03682Z'
          fill={props.color || 'white'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.6237 291.38C47.1749 297.333 29.2981 302.803 21.5886 303.798C19.2142 304.104 18.4138 302.09 19.4349 299.932C76.3816 179.637 107.992 135.407 207.193 169.16C272.418 89.1772 324.561 45.1245 396.64 21.4422L490.914 111.358C467.542 178.737 422.497 232.099 337.638 296.129C370.88 389.615 334.744 418.318 203.122 478.719C200.83 479.771 198.288 477.702 198.965 475.281C206.05 449.937 212.978 428.687 218.491 411.776C227.407 384.428 232.623 368.429 228.815 364.824C228.381 364.412 227.718 364.469 227.188 364.747L213.363 372.015C211.459 373.015 209.125 372.679 207.585 371.182L128.828 294.647C127.324 293.148 131.17 286.368 134.062 281.272C134.978 279.656 135.799 278.21 136.323 277.154C136.889 276.014 136.252 274.87 134.996 274.639C126.448 273.072 94.878 282.734 66.6258 291.38L66.6237 291.38ZM362.934 181.017C362.934 201.208 346.501 217.576 326.231 217.576C305.961 217.576 289.528 201.208 289.528 181.017C289.528 160.826 305.961 144.458 326.231 144.458C346.501 144.458 362.934 160.826 362.934 181.017ZM393.684 151.375C413.955 151.375 430.387 135.007 430.387 114.816C430.387 94.6249 413.955 78.2569 393.684 78.2569C373.414 78.2569 356.982 94.6249 356.982 114.816C356.982 135.007 373.414 151.375 393.684 151.375Z'
          fill={props.color || 'white'}
        />
      </ChakraIcon>
    ),
    google: (
      <ChakraIcon {...props} viewBox='0 0 533.5 544.3'>
        <path
          d='M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z'
          fill='#4285f4'
        />
        <path
          d='M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z'
          fill='#34a853'
        />
        <path d='M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z' fill='#fbbc04' />
        <path
          d='M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z'
          fill='#ea4335'
        />
      </ChakraIcon>
    ),
    facebook: (
      <ChakraIcon {...props} viewBox='0 0 355 355'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M355 177.573C355 79.5098 275.523 0 177.5 0C79.477 0 0 79.5098 0 177.573C0 266.197 64.9001 339.664 149.768 353V228.915H104.688V177.573H149.768V138.442C149.768 93.9425 176.277 69.3454 216.814 69.3454C236.234 69.3454 256.553 72.8164 256.553 72.8164V116.517H234.162C212.122 116.517 205.232 130.202 205.232 144.261V177.572H254.455L246.592 228.914H205.23V352.999C290.099 339.687 354.999 266.221 354.999 177.572L355 177.573Z'
          fill='#1977F3'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M247.03 228.68L254.9 177.302H205.633V143.967C205.633 129.923 212.504 116.205 234.589 116.205H257V72.4734C257 72.4734 236.664 69 217.226 69C176.653 69 150.121 93.5896 150.121 138.145V177.303H105V228.681H150.121V352.852C159.164 354.274 168.433 355 177.877 355C187.321 355 196.59 354.25 205.633 352.852V228.681H247.032L247.03 228.68Z'
          fill='#FEFEFE'
        />
      </ChakraIcon>
    ),
    youtube: (
      <ChakraIcon {...props} viewBox='0 0 463 464'>
        <path
          d='M453.464 120.696C448.124 100.875 432.487 85.2471 412.656 79.9106C376.424 70 231.499 70 231.499 70C231.499 70 86.5738 70 50.3425 79.5294C30.892 84.8659 14.8739 100.875 9.53456 120.696C0 156.908 0 232 0 232C0 232 0 307.473 9.53456 343.304C14.8739 363.125 30.5106 378.753 50.3425 384.089C86.9552 394 231.499 394 231.499 394C231.499 394 376.424 394 412.656 384.471C432.487 379.134 448.124 363.506 453.464 343.685C462.998 307.473 462.998 232.381 462.998 232.381C462.998 232.381 463.379 156.908 453.464 120.696Z'
          fill='#FF0000'
        />
        <path d='M305.869 231.999L185.353 162.625V301.373L305.869 231.999Z' fill='white' />
      </ChakraIcon>
    ),
    twitter: (
      <ChakraIcon {...props} viewBox='0 0 324 324'>
        <path
          d='M162 324C251.47 324 324 251.47 324 162C324 72.5299 251.47 0 162 0C72.5299 0 0 72.5299 0 162C0 251.47 72.5299 324 162 324Z'
          fill='#2DAAE1'
        />
        <path
          d='M265.872 97.5591C258.231 100.95 250.024 103.242 241.395 104.272C250.202 98.9987 256.948 90.6418 260.134 80.6957C251.768 85.6645 242.612 89.1625 233.065 91.0378C225.295 82.7558 214.215 77.585 201.957 77.585C178.428 77.585 159.346 96.6592 159.346 120.202C159.346 123.537 159.722 126.792 160.444 129.916C125.025 128.136 93.6232 111.17 72.6044 85.3896C68.9343 91.6858 66.8335 99.0064 66.8335 106.817C66.8335 121.601 74.3581 134.644 85.7916 142.285C79.0232 142.075 72.4033 140.248 66.4853 136.957V137.495C66.4853 158.145 81.1731 175.364 100.67 179.28C97.0887 180.262 93.33 180.774 89.4414 180.774C86.6921 180.774 84.0247 180.514 81.419 180.024C86.8423 196.95 102.581 209.27 121.225 209.618C106.639 221.051 88.261 227.867 68.3 227.867C64.862 227.867 61.4641 227.662 58.1284 227.26C76.9914 239.355 99.3881 246.416 123.456 246.416C201.841 246.416 244.71 181.477 244.71 125.162C244.71 123.313 244.669 121.478 244.58 119.649C252.932 113.608 260.142 106.128 265.872 97.5591V97.5591Z'
          fill='white'
        />
      </ChakraIcon>
    ),
    googleMap: (
      <ChakraIcon {...props} viewBox='0 0 92.3 132.3'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.3 132.3'>
          <path fill='#1a73e8' d='M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z' />
          <path fill='#ea4335' d='M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z' />
          <path
            fill='#4285f4'
            d='M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3'
          />
          <path
            fill='#fbbc04'
            d='M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3'
          />
          <path
            fill='#34a853'
            d='M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2'
          />
        </svg>
      </ChakraIcon>
    ),
  }

  return svgs[props.name] || null
}

type IconSource = 'octicon' | 'custom' | 'feather' | string

type Resize = boolean | number | string | ResponsiveValue<CSS.Property.FontSize<React.ReactText>>

export interface IconProps extends Omit<BoxProps, 'resize'> {
  color?: any
  color2?: any
  source?: IconSource
  resize?: Resize
  size?: any
  name?: string
  fill?: string
  minWidth?: any
  passedRef?: any
  style?: React.CSSProperties
  strokeWidth?: number
}

/**
 * Define Component Here
 * @param color
 * @param color2
 * @param source
 * @param resize
 * @param size
 * @param name
 * @param fill
 * @param minWidth
 * @param passedRef
 * @param style
 */
const Icon: React.FC<IconProps> = ({ resize, ...props }) => {
  const themeColor = get(theme.colors, props.color) || props.color
  let Component = null
  if (props.source === 'octicon') {
    const size = props.size || 16
    const scale = size / 16
    Component = <Octicon {...props} color={themeColor} icon={Octicons[props.name]} scale={scale} />
  } else if (props.source === 'custom') {
    if (resize) {
      let width: ResponsiveValue<CSS.Property.FontSize<React.ReactText>> = 1
      if (resize !== true && resize !== 'true') {
        width = resize
      }
      Component = (
        <Box width={width} {...props}>
          <CustomIcon {...props} color={themeColor} />
        </Box>
      )
    } else {
      Component = (
        // <Box {...props}>
        //   <CustomIcon {...props} color={themeColor} />
        // </Box>
        // <ChakraIcon>
        <CustomIcon {...props} boxSize={props.size || 10} />
        // </ChakraIcon>
      )
    }
  } /* defaults to feather */ else {
    const allProps = { ...props }
    if (allProps.fill) allProps.fill = get(theme.colors, props.color) || themeColor
    delete allProps.minWidth
    delete allProps.passedRef
    const iconProps = { ...allProps }
    if (resize) {
      let width: ResponsiveValue<CSS.Property.FontSize<React.ReactText>> = 'full'
      if (resize !== true && resize !== 'true') {
        width = resize
      }
      const handOffProps = { ...props }
      delete handOffProps.passedRef
      Component = (
        <Box ref={props.passedRef} width={width} {...handOffProps}>
          <FeatherIcon {...allProps} color={themeColor} icon={props.name} size='100%' />
        </Box>
      )
    } else {
      Component = (
        <Box {...allProps}>
          <FeatherIcon {...iconProps} color={themeColor} icon={props.name} size={props.size || 16} />
        </Box>
      )
    }
  }
  return Component
}

export default Icon
